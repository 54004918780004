#SimpleHero__image {
  background-size: cover;
  background-position: center center;
  height: 50vh;
  background-attachment: fixed;
}

.SimpleHero .b-example-divider {
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.SimpleHero h1 {
  color: black;
}

.SimpleHero p {
  color: #003049;
  font-weight: bold;
  font-size: 1rem;
}