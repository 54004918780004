// Custom.scss
// Option A: Include all of Bootstrap

// Custom variables
$header-color: #E0D8B0;

// Your variable overrides
$body-color: #111;

$theme-colors: (
  "primary": #417D7A,
  "danger": #8E3200
);

@import "node_modules/bootstrap/scss/bootstrap";

a,a:hover {
  /*color: black;*/
  color: #6c757d;
  text-decoration: none;
}

.nav-link {
  color: black;
  text-decoration: none;
}

.container {
  margin-bottom: 0.5rem;
}

header {
  background-color: $header-color;
}

footer {
  background-color: $header-color;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}