@-webkit-keyframes squiggly-anim {
  0% {
    filter: url("#squiggly-0");
  }
  25% {
    filter: url("#squiggly-1");
  }
  50% {
    filter: url("#squiggly-2");
  }
  75% {
    filter: url("#squiggly-3");
  }
  100% {
    filter: url("#squiggly-4");
  }
}

@keyframes squiggly-anim {
  0% {
    filter: url("#squiggly-0");
  }
  25% {
    filter: url("#squiggly-1");
  }
  50% {
    filter: url("#squiggly-2");
  }
  75% {
    filter: url("#squiggly-3");
  }
  100% {
    filter: url("#squiggly-4");
  }
}

.NotFound {
  width: 100%;
  min-height: calc(100vh - 60px);
  /*background: url("../../assets/images/notFound.png"), #1b1b25;*/
  background: #1b1b25;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.NotFound__header {
  right: 0;
}

.NotFound__image {
  padding: 5px 5px;
  max-width: 80%;
  max-height: fit-content;
}

@media (min-width:768px){
  .NotFound__image{
    max-width: 357px;
    max-height: 470px;
  }
}

.NotFound__header h1 {
  text-align: center;
  font-size: 120px;
  -webkit-animation: squiggly-anim 0.34s linear infinite;
  animation: squiggly-anim 0.34s linear infinite;
}

.NotFound h2 {
  text-align: right;
  font-size: 40px;
  margin-bottom: 20px;
  -webkit-animation: squiggly-anim 0.34s linear infinite;
  animation: squiggly-anim 0.34s linear infinite;
}

.NotFound__quoteContainer {
  margin: 14px;
  bottom: 0;
  right: 0;
}

.quote {
  text-align: right;
  font-size: 16px;
  color: darkgrey;
  font-style: italic;
  white-space: pre-line;
}

.cite {
  text-align: right;
  font-size: 14px;
  color: darkkhaki;
}
