#ContactUsForm {
  min-width: 60%;
  max-width: 80%;
  margin-left: 0;
  margin-top: 40px;
  margin-bottom: 20px;
  background-color: whitesmoke;
}

.AboutUs section {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

}

@media (min-width:768px){
  .AboutUs section{
    flex-direction: row;
  }
}

.AboutUs img {
  max-width: 50%;
  flex: 1;
}

.AboutUs p {
  padding: 15px;
}
