/* Cambios globales sobre Bootstrap */
html{
  box-sizing: border-box;
}

*, *:before, *:after{
  box-sizing: inherit;
}

@import url("https://fonts.googleapis.com/css?family=Cabin:300,400,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Flamenco&display=swap');
@import url("https://fonts.googleapis.com/css?family=Parisienne&display=swap");
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p{
  font-family: "Cabin", "Helvetica Neue", sans-serif;
}

h1,h2 {
  font-family: "Parisienne", cursive;
}

a.link-unstyled {
  color: inherit;
}

a.link-unstyled:hover {
  color: inherit;
  text-decoration: none;
}
